import React from "react";

const Item = ({index,title,body}) => {
    return (
        <>
            <div class="card z-depth-0 bordered">
                <div class="card-header" id="heading1">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" 
                        type="button" 
                        data-toggle="collapse" 
                        data-target={"#collapse"+index} 
                        aria-expanded="false" 
                        aria-controls={"#collapse"+index} 
                        dangerouslySetInnerHTML={{ __html: title }}>
                        </button>
                    </h5>
                </div>
                <div id={"collapse"+index} class="collapse" aria-labelledby="heading1" data-parent="#accordionExample">
                    <div class="card-body" dangerouslySetInnerHTML={{ __html: body }}></div>
                </div>
            </div>
        </>
    )
};

export default Item;