import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export  function Banner() {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClickContact = (e) => {
        e.preventDefault();
        navigate('/contact');
      }

    return (
        <>
            <div class="container-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                    </div>
                    <div class="col-md-9">
                        <h1 class="text-right mb-6 he">{t("solutions.banner.title")}</h1>
                    </div>
                </div>
                <div class="separar"></div>
                <div class="row">
                    <div class="col-md-12">
                        <h4 class="text-right">{t("solutions.banner.subtitle")}</h4>
                        <div class="separar"></div>
                        <a href="javascript:void();" onClick={(e) => handleClickContact(e)} class="btn btn-primary float-right">{t("index.banner.button")}</a>
                    </div>
                </div>
            </div>
            </div>
        </>
        
        )
}

export default Banner