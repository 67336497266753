import React from 'react'
import { Header } from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/solutions/Banner'
import Services from '../components/solutions/Services'

export  function Solutions() {

  return (
      <>
        <Banner />
        <Services />
      </>
      
    )
}

export default Solutions