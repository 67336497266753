import React from 'react'
import { Header } from '../components/Header'
import Banner from '../components/index/Banner'
import Benefits from '../components/index/Benefits'
import Testimonials from '../components/index/Testimonials'
import Footer from '../components/Footer'

export  function Index() {

  return (
      <>
        <Banner />
        <Benefits />
        <Testimonials />
      </>
      
    )
}

export default Index