import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CookieConsent from './CookieConsent';

export  function Footer() {

  const navigate = useNavigate();
  const year = new Date().getFullYear();
  const {t} = useTranslation();

  const handleClickSolutions = (e) => {
    e.preventDefault();
    navigate('/solutions');
  }
  const handleClickContact = (e) => {
    e.preventDefault();
    navigate('/contact');
  }
  const handleClickFaq = (e) => {
    e.preventDefault();
    navigate('/faq');
  }

  const handleClickTerms = (e) => {
    e.preventDefault();
    navigate('/terms');
  }
  const handleClickPrivacy = (e) => {
    e.preventDefault();
    navigate('/privacy');
  }

  return (
      <>
      <CookieConsent />
        <div class="container-fluid footer-ink">
          <div class="container">
            <div class="row">
              <div class="d-flex flex-column align-items-center col-md-4 padding-footer">
                <ul>
                  <li><a href="javascript:void();" onClick={(e) => handleClickSolutions(e)}>{t("pages.solutions")}</a></li>
                  <li><a href="javascript:void();" onClick={(e) => handleClickContact(e)}>{t("pages.contact")}</a></li>
                  <li><a href="javascript:void();" onClick={(e) => handleClickFaq(e)}>{t("pages.faqs")}</a></li>
                </ul>
              </div>
              <div class="d-flex flex-column  align-items-center col-md-4 padding-footer">
                <ul>
                  <li><a href="javascript:void();" onClick={(e) => handleClickTerms(e)}>{t("pages.terms")}</a></li>
                  <li><a href="javascript:void();" onClick={(e) => handleClickPrivacy(e)}>{t("pages.privacy")}</a></li>
                </ul>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center col-md-4 footer-social">
                <div class="d-flex align-items-center">
                  <a href="https://www.facebook.com/Inkitdistribuciondigital/"><i class="fab fa-facebook"></i></a> 
                  <a href="https://www.youtube.com/watch?v=9RLMqmc7huk"><i class="fab fa-youtube"></i></a> 
                  <a href="https://twitter.com/inkit_latam"><i class="fab fa-twitter"></i></a>
                  <a href="https://www.linkedin.com/in/ink-it-distribucion?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"><i class="fab fa-linkedin-in"></i></a>
                  <a href="https://www.instagram.com/ink_it_global?igsh=MTIweWVnaXJ6Nndpaw=="><i class="fab fa-instagram"></i></a>
                </div>
                <p class="copyright">&#169;{year} Ink it </p>
              </div>
            </div>
          </div>  
        </div>   
        <a href="https://api.whatsapp.com/send?phone=5585288576&text=Quiero%20m%C3%A1s%20informaci%C3%B3n%20de%20acerca%20de%20Ink%20it." class="float" target="_blank">
          <i class="fa fa-whatsapp my-float"></i>
        </a> 
      </>
      
    )
}

export default Footer