import React, { useEffect, useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

const FormContact = () => {

    //clave recaptha
    const key = '6LeYYecpAAAAAH3-VTOxehUxDn4NWSn9BXw--zH-'
    const [name, setName] = useState("");
    const [job, setJob] = useState("");
    const [company, setCompany] = useState("");
    const [subject, setSubject] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [loading, setLoading] = useState(false);
    

    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const { t } = useTranslation();

    const [loadingtext, setLoadingtext] = useState('');

    useEffect(() => {
        setLoadingtext(t('contact.text9'));
    }, [t]);

    

    const handleName = (event) => {
        setName(event.target.value);
    };
    const handleJob = (event) => {
        setJob(event.target.value);
    };
    const handleCompany = (event) => {
        setCompany(event.target.value);
    };
    const handleSubject = (event) => {
        setSubject(event.target.value);
    };
    const handleEmail = (event) => {
        setEmail(event.target.value);
    };
    const handleMessage = (event) => {
        setMessage(event.target.value);
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
      };

    const resetForm = () => {
        setName('');
        setJob('');
        setCompany('');
        setSubject('');
        setEmail('');
        setMessage('');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        

        if(name === '' || name === '' || name === '' || name === '' || name === '' || name === '' || name === ''){
            alert(t("contact.text12"));
            return;
        }else{
            if (!recaptchaToken) {
                alert(t("contact.text13"));
                return;
            }
        }

        try {
            setLoadingtext(t("contact.text10")+'...');
            setLoading(true);
            const formData = {
                name: name,
                job: job,
                company: company,
                subject: subject,
                email: email,
                message: message,
                recaptchaToken: recaptchaToken,
              };
            //const url = 'http://inkit_php/api/services/contactus.php';
            const url = 'https://accounts.ink-it.ink/api/services/contactus.php';
            const response = await fetch(url, {
                method: "POST",
                headers: {
                "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            });
            const data = await response.json();
            if(data && data.status == 'success'){
                setLoadingtext(t("contact.text11"));
                resetForm();
            }else{
                setLoadingtext(t("contact.text9"));
                alert(data.msg);
            }
            setLoading(false);
        } catch (error) {
            setLoadingtext(t("contact.text9"));
            setLoading(false);
          console.error("request failed:", error);
        }
      };

    return (
        <>
        <div class="separar"></div>
        <div class="separar"></div>
        <div class="separar"></div>
        <div class="separar"></div>
        <div class="separar"></div>

        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <p><span class="lverde">{t("contact.text1")}:</span> +52 (55) 52 54 38 52</p>
                    <p><span class="lverde">{t("contact.text2")}:</span> <a href="mailto:contacto@ink-it.ink">contacto@ink-it.ink</a></p>
                    
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <form className="form" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">{t("contact.text3")}:</label>
                        <input className="hs-input"
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            onChange={handleName}
                        />
                    </div>
                    <div>
                        <label htmlFor="job">{t("contact.text4")}:</label>
                        <input className="hs-input"
                        type="text"
                        id="job"
                        name="job"
                        value={job}
                        onChange={handleJob}
                        />
                    </div>
                    <div>
                        <label htmlFor="company">{t("contact.text5")}:</label>
                        <input className="hs-input"
                        type="text"
                        id="company"
                        name="company"
                        value={company}
                        onChange={handleCompany}
                        />
                    </div>
                    <div>
                        <label htmlFor="subject">{t("contact.text6")}:</label>
                        <input className="hs-input"
                        type="text"
                        id="subject"
                        name="subject"
                        value={subject}
                        onChange={handleSubject}
                        />
                    </div>
                    <div>
                        <label htmlFor="email">{t("contact.text7")}:</label>
                        <input className="hs-input"
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleEmail}
                        />
                    </div>
                    <div>
                        <label htmlFor="message">{t("contact.text8")}:</label>
                        <textarea className="hs-input"
                        id="message"
                        name="message"
                        value={message}
                        cols="50"
                        rows="4"
                        onChange={handleMessage}
                        />
                    </div>
                    <ReCAPTCHA
                       sitekey={key}
                       onChange={handleRecaptchaChange}
                    />
                    <br />
                    <button disabled={loading} class="btn btn-primary" type="submit">{loadingtext}</button>
                </form>
                </div>

            </div>
        </div>


        <div class="separar"></div>
        <div class="separar"></div>
        <div class="separar"></div>
        <div class="separar"></div>
        <div class="separar"></div>
        </>
    )
};

export default FormContact;