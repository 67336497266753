import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="page-not-found">
      <h1>{t('pageNotFound.title')}</h1>
      <p>{t('pageNotFound.description')}</p>
      <Link to="/">{t('pageNotFound.homeLink')}</Link>
    </div>
  );
};

export default PageNotFound;
