import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const CookieConsent = () => {
    const [show, setShow] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setShow(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setShow(false);
    };

    const handleClickPrivacy = (e) => {
        e.preventDefault();
        navigate('/privacy');
      }

    if (!show) return null;

    return (
        <div style={styles.container}>
            <p style={styles.message}>
            {t("cookies_consent.text1")}
                <a href="javascript:void();" onClick={(e) => handleClickPrivacy(e)} style={styles.link}> {t("cookies_consent.text2")}</a>
            </p>
            <button onClick={handleAccept} style={styles.button}>
            {t("cookies_consent.button_text")}
            </button>
        </div>
    );
};

const styles = {
    container: {
        position: 'fixed',
        bottom: '20px',
        left: '20px',
        right: '20px',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        padding: '20px',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 1000,
    },
    message: {
        margin: 0,
        paddingRight: '20px',
    },
    link: {
        color: '#7ebb00',
        textDecoration: 'underline',
    },
    button: {
        backgroundColor: '#7ebb00',
        color: 'black',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '5px',
        cursor: 'pointer',
    }
};

export default CookieConsent;
