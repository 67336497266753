import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export  function Benefits() {

    const navigate = useNavigate();

    const { t } = useTranslation();

    const handleClickContact = (e) => {
        e.preventDefault();
        navigate('/contact');
      }

    return (
        <>
            <div class="container-blue">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2 class="mt-6">{t("index.benefits.title")}</h2>
                        </div>
                    </div>
                    <div class="separar"></div>
                    <div class="row mt-6">
                        <div class="col-md-6">
                            <h4>{t("index.benefits.subtitle1")}</h4>
                            <p class="mt-2">{t("index.benefits.description1")}</p>
                        </div>
                        <div class="col-md-6">
                            <h4>{t("index.benefits.subtitle2")}</h4>
                            <p class="mt-2">{t("index.benefits.description2")}</p>
                        </div>
                    </div>
                    <div class="separar"></div>
                    <div class="row mt-6">
                        <div class="col-md-6">
                            <h4>{t("index.benefits.subtitle3")}</h4>
                            <p class="mt-2">{t("index.benefits.description3")}</p>
                        </div>
                        <div class="col-md-6">
                            <h4>{t("index.benefits.subtitle4")}</h4>
                            <p class="mt-2">{t("index.benefits.description4")}</p>
                        </div>
                    </div>
                    <div class="separar"></div>
                    <div class="row mt-6">
                        <div class="col-md-6">
                            <h4>{t("index.benefits.subtitle5")}</h4>
                            <p class="mt-2">{t("index.benefits.description5")}</p>
                        </div>
                        <div class="col-md-6">
                            <h4>{t("index.benefits.subtitle6")}</h4>
                            <p class="mt-2">{t("index.benefits.description6")}</p>
                            <p>{t("index.benefits.description6_1")}</p>
                        </div>
                    </div>
                    <div class="separar"></div>
                    <div class="row mt-6">
                        <div class="col-md-12 text-center">
                            <a href="javascript:void();" onClick={(e) => handleClickContact(e)} class="btn btn-primary">{t("index.benefits.button")}</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
        
        )
}

export default Benefits