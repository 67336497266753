import React from 'react'
import { useTranslation } from 'react-i18next';

export  function Terms() {

  const { t } = useTranslation();

  return (
      <>
        <div class="separar"></div>

        <div class="container-fluid contenido text-center">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <h3>{t("pages.terms")}</h3>
              <div class="separar"></div>
            </div>
          </div>
        </div>
        <div class="separar"></div>


        <div class="container faqs">
          <div class="row justify-content-center">
            <div class="col-md-10 letras-chiquitas">
              <p>{t("terms")}</p>
            </div>
          </div>
        </div>

        <div class="separar"></div>
        <div class="separar"></div>
        <div class="separar"></div>
      </>
      
    )
}

export default Terms