import React from "react";
import Item from "./Item";
import { useTranslation } from 'react-i18next';

const Index = () => {

    const { t } = useTranslation();

    const faqs = [
        {
            title: t("faqs.card1.title"),
            body: '<p>'+t("faqs.card1.body")+'</p>'
        },
        {
            title: t("faqs.card2.title"),
            body: '<p>'+t("faqs.card2.body")+'</p>'
        },
        {
            title: t("faqs.card3.title"),
            body: '<p>'+t("faqs.card3.body")+'</p>'
        },
        {
            title: t("faqs.card4.title"),
            body: '<p>'+t("faqs.card4.body")+'</p>'
        },
        {
            title: t("faqs.card5.title"),
            body: '<p>'+t("faqs.card5.body")+'</p>'
        },
        {
            title: t("faqs.card6.title"),
            body: '<p>'+t("faqs.card6.body")+'</p>'
        },
        {
            title: t("faqs.card7.title"),
            body: '<p>'+t("faqs.card7.body")+'</p>'
        },
        {
            title: t("faqs.card8.title"),
            body: '<p>'+t("faqs.card8.body")+'</p>'
        },
        {
            title: t("faqs.card9.title"),
            body: '<p>'+t("faqs.card9.body")+'</p>'
        },
        {
            title: t("faqs.card10.title"),
            body: '<p>'+t("faqs.card10.body")+'</p>'
        },
        {
            title: t("faqs.card11.title"),
            body: '<p>'+t("faqs.card11.body")+'</p>'
        },

        {
            title: t("faqs.card12.title"),
            body: '<p>'+t("faqs.card12.body")+'</p>'
        },

        {
            title: t("faqs.card13.title"),
            body: '<p>'+t("faqs.card13.body")+'</p>'
        },

        {
            title: t("faqs.card14.title"),
            body: '<p>'+t("faqs.card14.body")+'</p>'
        },

        {
            title: t("faqs.card15.title"),
            body: '<p>'+t("faqs.card15.body")+'</p>'
        },

        {
            title: t("faqs.card16.title"),
            body: '<p>'+t("faqs.card16.body")+'</p>'
        },

        {
            title: t("faqs.card17.title"),
            body: '<p>'+t("faqs.card17.body")+'</p>'
        }
    ];
    return (
        <>
            <div class="separar"></div>
            <div class="container-fluid contenido text-center">
                <div class="row justify-content-center">
                    <div class="col-md-12">
                        <h3>{t("pages.faqs")}</h3>
                        <div class="separar"></div>
                    </div>
                </div>
            </div>
            <div class="container faqs">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="accordion" id="accordionExample">
                            {
                                faqs.map((elem, index)=>{
                                    return <Item key={index} index={index} title={elem.title} body={elem.body} />
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Index;