import React from 'react'
import logo01 from '../../assets/img/logos-transparentes/001_imcp.png';
import logo02 from '../../assets/img/logos-transparentes/002_colson.png';
import logo03 from '../../assets/img/logos-transparentes/003_santillana.png';
import logo04 from '../../assets/img/logos-transparentes/004_IPN.png';
import logo05 from '../../assets/img/logos-transparentes/005_uam.png';
import logo06 from '../../assets/img/logos-transparentes/006_loarousse.png';
import logo07 from '../../assets/img/logos-transparentes/007_inah.png';
import logo08 from '../../assets/img/logos-transparentes/008_tecnologico.png';
import logo09 from '../../assets/img/logos-transparentes/009_trillas.png';
import logo10 from '../../assets/img/logos-transparentes/0010_guadalajara.png';
import logo11 from '../../assets/img/logos-transparentes/0011_sinaloa.png';
import logo12 from '../../assets/img/logos-transparentes/0012_icb.png';
import logo13 from '../../assets/img/logos-transparentes/0013_BUAP.png';
import logo14 from '../../assets/img/logos-transparentes/0014_uag.png';
import logo15 from '../../assets/img/logos-transparentes/0015_artes.png';
import logo16 from '../../assets/img/logos-transparentes/0016_colmilloblancologo.png';
import logo17 from '../../assets/img/logos-transparentes/0017_intersistemas.png';
import logo18 from '../../assets/img/logos-transparentes/0018_clube.png';
import logo19 from '../../assets/img/logos-transparentes/0019plata.png';
import logo20 from '../../assets/img/logos-transparentes/0020_Cide.png';
import logo21 from '../../assets/img/logos-transparentes/0021_seminario.png';
import logo22 from '../../assets/img/logos-transparentes/0022_algarabia.png';
import logo23 from '../../assets/img/logos-transparentes/0023_Colnal.png';
import logo24 from '../../assets/img/logos-transparentes/0024_colmex.png';
import logo25 from '../../assets/img/logos-transparentes/0025_lasalle.png';

export  function Logos() {

    const logos = [
        logo01,logo02,logo03,logo04,logo05,logo06,logo07,logo08,logo09,logo10,logo11,
        logo12,logo13,logo14,logo15,logo16,logo17,logo18,logo19,logo20,logo21,logo22,
        logo23,logo24,logo25
    ];

    return (
        <>
            <div className="slider-smooth">
                <div className="slide-track">
                    {
                        logos.map((elem, index)=>{
                            return <div className='slide'><img src={elem} alt={'logo_'+index} /></div>
                        })
                    }
                </div>
            </div>
        </>
        
        )
}

export default Logos