import React from 'react'
import { Header } from '../components/Header'
import Footer from '../components/Footer'
import Index from '../components/faq/Index'

export  function Faq() {

  return (
      <>
        <Index />
      </>
      
    )
}

export default Faq