import React from 'react'
import { Header } from '../components/Header'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next';

export  function Privacy() {

  const { t } = useTranslation();

  return (
      <>
        <div class="separar"></div>

        <div class="container-fluid contenido text-center">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <h3>{t("pages.privacy")}</h3>
              <div class="separar"></div>
            </div>
          </div>
        </div>


        <div class="separar"></div>

        <div class="container faqs">
          <div class="row justify-content-center letras-chiquitas">
            <div class="col-md-10">

        {/* <p>Sextil Online SA de CV, mejor conocido como Ink it, con domicilio en calle Juan Salvador Agraz 61, colonia Santa Fe, CDMX, municipio o delegación Cuajimalpa, c.p. 05348, país México, y portal de internet www.ink-it.ink, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:</p> */}
        <p>{t("privacy.text1")}</p>

        <p><strong>{t("privacy.text2")}</strong></p>

        <p>{t("privacy.text3")}</p>

        <ol>
          <li>{t("privacy.text4")}</li>
          <li>{t("privacy.text5")}</li>
        </ol>


        <p><strong>{t("privacy.text6")}</strong></p>
        <p>{t("privacy.text7")}</p>

        <ol>
          <li>{t("privacy.text8")}</li>
          <li>{t("privacy.text9")}</li>
          <li>{t("privacy.text10")}</li>
          <li>{t("privacy.text11")}</li>
        </ol>

        <p><strong>{t("privacy.text12")}</strong></p>
        <p>{t("privacy.text13")}</p>
        <p>{t("privacy.text14")}</p>
        <p>{t("privacy.text15")}</p>
        <p>{t("privacy.text16")}</p>
        <p>{t("privacy.text17")}</p>
        <p>{t("privacy.text18")}</p>
        <p>a) {t("privacy.text19")}</p>
        <p>b) {t("privacy.text20")}</p>
        <p>c) {t("contact.text7")}: <a href="mailto:direccionadmon@ink-it.ink">direccionadmon@ink-it.ink</a></p>
        <p>d) {t("contact.text14")}: 55-52543852</p>
        <p><strong>{t("privacy.text21")}</strong></p>
        <p>{t("privacy.text22")}</p>
        <p>{t("privacy.text23")} <a href="mailto:ontacto@ink-it.ink">contacto@ink-it.ink</a></p>
        <p>{t("privacy.text24")}</p>
        <p>{t("privacy.text25")}: <a href="contacto@ink-it.ink">contacto@ink-it.ink</a></p>
        <p><strong>{t("privacy.text26")}</strong></p>
        <p>{t("privacy.text27")}:</p>
        <p>{t("privacy.text28")}: <a href="contacto@ink-it.ink">contacto@ink-it.ink</a></p>
        <p><strong>{t("privacy.text29")}</strong></p>
        <p>{t("privacy.text30")}:</p>
        <p>{t("privacy.text31")}</p>
        <p>{t("privacy.text32")}:</p>
        <p>{t("privacy.text33")}</p>
        <p>{t("privacy.text34")}</p>
        <p>{t("privacy.text35")}</p>
        <p>{t("privacy.text36")}</p>
        <p>{t("privacy.text37")}</p>
        <p>{t("privacy.text38")}</p>
        <p>{t("privacy.text39")}</p>
        <p>{t("privacy.text40")}</p>
        <p><strong>{t("privacy.text41")}</strong></p>
        <p>{t("privacy.text42")}</p>
        <p>{t("privacy.text43")}</p>
        <p>{t("privacy.text44")}:</p>
        <p>{t("privacy.text45")}</p>
        <p>{t("privacy.text46")}: 27/05/2024</p>


        <h5>{t("privacy.text47")}</h5>

        <p>{t("privacy.text48")}:</p>
        <p><small>{t("privacy.text49")}</small></p>
        <p>{t("privacy.text50")}</p>
        <p><small>{t("privacy.text51")}</small></p>
        <p>{t("privacy.text52")}</p>
        <p><small>{t("privacy.text53")}</small></p>
        <p>{t("privacy.text54")}:</p>
        <ol>
          <li>{t("privacy.text55")}</li>
          <li>{t("privacy.text56")}</li>
        </ol>

        <p>{t("privacy.text57")}</p>
        <p><small>{t("privacy.text58")}</small></p>
        <p>{t("privacy.text59")}</p>

              
            </div>
          </div>
        </div>

        <div class="separar"></div>
      </>
      
    )
}

export default Privacy