import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <li className="nav-item">
        <button
          className={`nav-link language ${currentLanguage === 'en' ? 'active' : ''}`}
          onClick={() => changeLanguage('en')}
        >
          <small>Eng</small>
        </button>
      </li>
      <li className="nav-item">
        <div className='separador'>/</div>
      </li>
      <li className="nav-item">
        <button
          className={`nav-link language ${currentLanguage === 'es' ? 'active' : ''}`}
          onClick={() => changeLanguage('es')}
        >
          <small>Esp</small>
        </button>
      </li>
    </>
  );
};

export default LanguageSelector;
