import React from 'react'
import {Router} from './Router'
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

export function App(){

    return (
        <section>
            <I18nextProvider i18n={i18n}>
                <Router />  
            </I18nextProvider>
        </section>
        
    )
}

export default  App;