import React from 'react'
import { Header } from '../components/Header'
import Footer from '../components/Footer'
import  FormContact  from '../components/contact/Form'

export  function ContactForm() {

  return (
      <>
        <FormContact />
      </>
      
    )
}

export default ContactForm