import React from 'react'

export  function Testimonial({text, client, account, classBackground}) {

  return (
      <>
        <div className={classBackground}>
            <p dangerouslySetInnerHTML={{ __html: text }} />
            <div class="row">
                <div class="col-md-6">
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                </div>
                <div class="col-md-6 text-right">
                    <h5>{client}</h5>
                    <h6>{account}</h6>
                </div>
            </div>
        </div>

      </>
      
    )
}

export default Testimonial