import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import Solutions from "./pages/Solutions";
import ContactForm from "./pages/ContactForm";
import Faq from "./pages/Faq";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import PageNotFound from "./pages/PageNotFound";
import { Header } from "./components/Header";
import Footer from "./components/Footer";

export const Router = () => {

    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/" element={<Index />} />
                <Route path="*" element={<PageNotFound />} />
                <Route path="/solutions" element={<Solutions />} />
                <Route path="/contact" element={<ContactForm />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
};
