import React from "react";
import imgLogo from '../assets/img/logoInk.png'
import { useNavigate } from "react-router-dom";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from 'react-i18next';

export function Header(props){

  const navigate = useNavigate();

  const { t } = useTranslation();


  const handleClickSolutions = (e) => {
    e.preventDefault();
    navigate('/solutions');
  }
  const handleClickContact = (e) => {
    e.preventDefault();
    navigate('/contact');
  }
  const handleClickFaq = (e) => {
    e.preventDefault();
    navigate('/faq');
  }

  const handleClickIndex = (e) => {
    e.preventDefault();
    navigate('/');
  }

  return(
    <nav className="navbar navbar-expand-lg primary-menu">
			<div className="container">
				<a className="navbar-brand" href="javascript:void();" onClick={(e) => handleClickIndex(e)}>
				  <img src={imgLogo} alt="Logotipo Ink it" height="65" className="d-inline-block align-top" />
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#basicExampleNav"
          aria-controls="basicExampleNav" aria-expanded="false" aria-label="Toggle navigation">
				<i className="fas fa-bars"></i>
			  </button>
        <div className="collapse navbar-collapse" id="basicExampleNav">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <a className="nav-link" href="javascript:void();" onClick={(e) => handleClickSolutions(e)}>{t('pages.solutions')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="javascript:void();" onClick={(e) => handleClickContact(e)}>{t('pages.contact')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="javascript:void();" onClick={(e) => handleClickFaq(e)}>{t('pages.faqs')}</a>
            </li>
{/*             <li className="nav-item">
              <a className="nav-link" href="https://ink-it.ink/beca-juan-grijalbo-32-anos-de-amor-por-el-libro/">Blog</a>
            </li> */}

          </ul>
          <ul className="form-inline my-2 my-lg-0">
            <li className="nav-item">
              <a className="nav-link login" href="https://app.ink-it.ink">{t('pages.login')}</a>
            </li>
            <LanguageSelector />
            {/* <li className="nav-item">
              <a className="nav-link language" href="https://ink-it.ink"><small>Esp</small></a>
            </li>
            <li className="nav-item">
              <a className="nav-link separador">/</a>
            </li>
            <li className="nav-item">
              <a className="nav-link language" href="https://ink-it.ink/en"><small>Eng</small></a>
            </li> */}

          </ul>
        </div>
        </div>
		</nav>
  )  
}